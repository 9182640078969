import {
  gql,
  useQuery,
  useMutation,
  ApolloError,
  MutationOptions,
  FetchResult,
} from '@apollo/client';
import { Moment } from 'moment';
import { useUserContext } from '../..';
import { DeskReservationVisibility } from '../../../__generated__/types';
import {
  ReserveDeskForMeMutation,
  ReserveDeskForMeMutationVariables,
  GetDeskInfoQuery,
  GetDeskInfoQueryVariables,
} from './../hooks/__generated__/useDeskDetails.generated';
import { DeskDetails, DeskReservationVisibilityType } from '../../../types';
import { getReservationTypeForBooking } from '../../../utilities';

const DESK_DETAILS_QUERY = gql`
  query GetDeskInfo(
    $deskId: ID!
    $startTime: Date!
    $endTime: Date!
    $userId: Int!
  ) {
    getDeskById(id: $deskId) {
      id
      type
      amenities {
        id
        name
        quantity
      }
      name
      level {
        id
        name
      }
      zone {
        id
        name
      }
      reservationPolicies {
        seatReservationVisibilityEnabled
      }
      state(startTime: $startTime, endTime: $endTime, userId: $userId) {
        availability {
          status
          unbookableReasons {
            reason
            value
          }
        }
        reservations {
          id
          startTime
          endTime
          visibility
          accountReservee {
            user {
              id
              name
              avatar
              slug
            }
          }
        }
      }
    }
  }
`;

const RESERVE_DESK_MUTATION = gql`
  mutation ReserveDeskForMe(
    $seatId: Int!
    $type: String!
    $start: DateWithTimezone!
    $end: DateWithTimezone!
    $visibility: DeskReservationVisibility
  ) {
    reserveDeskForMe(
      seatId: $seatId
      type: $type
      start: $start
      end: $end
      visibility: $visibility
    ) {
      id
    }
  }
`;

type ReturnType = {
  details?: DeskDetails;
  loading: boolean;
  error?: ApolloError;
  reserveDesk: (
    options?: MutationOptions<
      ReserveDeskForMeMutation,
      ReserveDeskForMeMutationVariables
    >
  ) => Promise<FetchResult<ReserveDeskForMeMutation>>;
  isReserving: boolean;
};

export const useDeskDetails = (
  timeZone: string,
  startTime: Moment,
  endTime: Moment,
  deskId?: string,
  visibility?: DeskReservationVisibilityType
): ReturnType => {
  const { currentUser } = useUserContext();
  const visibilityEnum = visibility
    ? DeskReservationVisibility[visibility]
    : undefined;
  const { data, loading, error } = useQuery<
    GetDeskInfoQuery,
    GetDeskInfoQueryVariables
  >(DESK_DETAILS_QUERY, {
    errorPolicy: 'all',
    variables: {
      deskId: deskId || '',
      startTime,
      endTime,
      userId: Number(currentUser?.id),
    },
    skip: !deskId || !currentUser,
  });
  const [reserveDesk, { loading: isReserving }] = useMutation<
    ReserveDeskForMeMutation,
    ReserveDeskForMeMutationVariables
  >(RESERVE_DESK_MUTATION, {
    refetchQueries: ['UseDayListData', 'GetUserSchedule'],
    variables: {
      seatId: Number(deskId),
      type: getReservationTypeForBooking(data?.getDeskById?.type),
      start: {
        dateTime: startTime.clone().utc().format(),
        timeZone,
      },
      end: {
        dateTime: endTime.clone().utc().format(),
        timeZone,
      },
      visibility: visibilityEnum,
    },
  });

  return {
    details: mapQueryResultToDeskdetails(data),
    loading,
    error,
    reserveDesk,
    isReserving,
  };
};

const mapQueryResultToDeskdetails = (
  deskDetails: GetDeskInfoQuery | undefined
): DeskDetails | undefined => {
  if (deskDetails && deskDetails.getDeskById) {
    const desk = deskDetails.getDeskById;
    return {
      id: desk.id,
      type: desk.type,
      amenities: desk.amenities.map((x) => x),
      name: desk.name,
      level: desk.level,
      zone: desk.zone || null,
      seatReservationVisibilityEnabled:
        desk.reservationPolicies?.seatReservationVisibilityEnabled,
      availability: {
        status: desk.state.availability.status,
        unbookableReasons: desk.state?.availability?.unbookableReasons?.map(
          (x) => ({
            reason: x.reason,
            value: x.value,
          })
        ),
      },
      reservations: desk.state.reservations.map((x) => x),
    };
  }
  return undefined;
};
