import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import moment, { Moment } from 'moment-timezone';
import { useApolloContext, useUserContext } from '../..';
import { buildDatesArrayFromRange } from '../../../utilities';
import {
  GetUserScheduleDeskReservations,
  GetUserScheduleQuery,
  GetUserScheduleQueryVariables,
} from './__generated__/useUserOfficeSchedule.generated';

export type UserReservation = GetUserScheduleDeskReservations;

const query = gql`
  query GetUserSchedule($id: ID!, $dateRanges: [DateRangeInput!]!) {
    getUserInOfficeDataByDay(id: $id, dateRanges: $dateRanges) {
      date
      userInOffice {
        userId
        employeeVisits {
          id
          locationId
          startTime
          endTime
        }
        deskReservations {
          id
          type
          startTime
          endTime
          type
          accountReservee {
            participationStatus
            visitorId
          }
          healthCheckpoint {
            status
            surveyId
            surveyUrl
          }
          seat {
            id
            name
            level {
              id
              name
            }
            zone {
              id
              name
            }
            location {
              id
              name
            }
            level {
              id
              name
            }
            reservationPolicies {
              checkinPolicies {
                enforceLocalCheckinOnly
                checkInWindow
                assignedCheckInEnabled
              }
            }
          }
          confirmation {
            status
            confirmationWindowStart
            confirmationWindowEnd
            unconfirmableReasons {
              reason
              value
            }
          }
        }
      }
    }
  }
`;

type HookArgs = {
  start: Moment;
  end: Moment;
};

export function useUserOfficeSchedule({ start, end }: HookArgs) {
  const { currentUser } = useUserContext();
  const { tenantId } = useApolloContext();
  const dateRanges = useMemo(() => {
    return buildDatesArrayFromRange(start, end).map((currentDate) => {
      return {
        startTime: currentDate,
        endTime: currentDate.clone().endOf('day'),
        timeZone: moment.tz.guess(),
      };
    });
  }, [start, end]);

  return useQuery<GetUserScheduleQuery, GetUserScheduleQueryVariables>(query, {
    skip: !currentUser?.id || !tenantId,
    variables: {
      id: currentUser?.id as string,
      dateRanges,
    },
  });
}
