import styled from '@emotion/styled';
import {
  GetUserScheduleDeskReservations as Reservation,
  GetUserScheduleEmployeeVisits as Visit,
} from '../../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { CheckInRequirements, InOfficeLocationInfo } from './components';
import { ReservationDropdownOptions } from '../Dropdowns';
import { Moment } from 'moment';
import { isBeforeToday, isToday } from '../../../../utilities';
import moment from 'moment';
import { Location } from '../../../../contexts/Location/hooks/useOrgLocations';
import { useCallback } from 'react';

type Props = {
  inOfficeLocation?: Location;
  visit?: Visit;
  reservation?: Reservation;
  date: Moment;
  handleCancelDesk: () => void;
  disabled?: boolean;
};

export const InOfficeInfo = ({
  inOfficeLocation,
  visit,
  reservation,
  date,
  handleCancelDesk,
  disabled,
}: Props): JSX.Element | null => {
  const isReservationBeforeToday = useCallback((startTime: string) => {
    const parsedStartTime = moment(startTime);
    return parsedStartTime.isValid() ? isBeforeToday(moment(startTime)) : false;
  }, []);

  return inOfficeLocation && (visit || reservation) ? (
    <InOfficeInfoCardContainer>
      <Top>
        <InOfficeLocationInfo
          inOfficeLocation={inOfficeLocation}
          reservation={reservation}
          hasVisit={!!visit}
          disabled={disabled}
        />
        {!disabled && reservation && reservation.type !== 'shared' && (
          <ReservationDropdownOptions
            reservation={reservation}
            dateIsToday={isToday(date)}
            onCancelDesk={handleCancelDesk}
          />
        )}
      </Top>
      {reservation && !isReservationBeforeToday(reservation?.startTime) && (
        <CheckInRequirements reservation={reservation} />
      )}
    </InOfficeInfoCardContainer>
  ) : null;
};

const InOfficeInfoCardContainer = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
`;
